'use strict';

var processInclude = require('sfraScripts/util');

$(document).ready(function () {

	$('body').on( 'click', '.opt-sort-by', function(){
		setTimeout(function(){
		$('#sort-tab').trigger('click');
	}, 100);
	});
	
	$('body').on( 'click', '.opt-filter', function(){
		setTimeout(function(){
		$('#filter-tab').trigger('click');
	}, 100);
	});

	// TODO: Remove this and find better approach to override styles
	if (window.location.pathname.includes("order-success")) {
		$("#maincontent > div.container-fluid.checkout-confirmation.receipt > div:nth-child(2) > div.col-12.col-lg > div.card.delivery-summary.order.order-product-summary.collapsible > div.cart-header.order.clearfix.collapsible-title.card-header-custom > span").parent().trigger("click").parent().removeClass("collapsible open").css("padding", ".75rem 1.25rem");
	}

	processInclude(require('./search/search'));
});

$('#sort').on('click', function(e) {
	e.preventDefault();
	$('body').on( 'click', '.opt-sort-by', function(){
		setTimeout(function(){
		$('#sort-tab').trigger('click');
	}, 100);
	});
	
	$('body').on( 'click', '.opt-filter', function(){
		setTimeout(function(){
		$('#filter-tab').trigger('click');
	}, 100);
	});
	$('#sort-tab-content').addClass('tab-pane fade active show');
	$('#filter-tab-content').removeClass('active show');
});

$('#filter').on('click', function(e) {
	e.preventDefault();
	$('body').on( 'click', '.opt-sort-by', function(){
		setTimeout(function(){
		$('#sort-tab').trigger('click');
	}, 100);
	});
	
	$('body').on( 'click', '.opt-filter', function(){
		setTimeout(function(){
		$('#filter-tab').trigger('click');
	}, 100);
	});
	$('#filter-tab-content').addClass('tab-pane fade active show');
	$('#sort-tab-content').removeClass('active show');
});

require('./components/spinner');
